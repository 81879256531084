<template>
  <b-row>

    <!-- Cashier Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Cashier Name"
        rules="required"
      >
        <b-form-group
          label="Cashier Name"
          label-for="cashier-name"
        >
          <b-form-input
            id="cashier-name"
            v-model="cashierData.name"
            :state="getValidationState(validationContext)"
            placeholder="Cashier Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- National Id -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="National Id"
        rules="required|digits:10"
      >
        <b-form-group
          label="National Id"
          label-for="national-id"
        >
          <b-form-input
            id="national-id"
            v-model="cashierData.nationalId"
            :state="getValidationState(validationContext)"
            placeholder="National Id"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Mobile Number -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Mobile Number"
        rules="required|digits:12"
      >
        <b-form-group
          label="Mobile Number"
          label-for="mobile-number"
        >
          <b-form-input
            id="mobile-number"
            v-model="cashierData.mobile"
            :state="getValidationState(validationContext)"
            placeholder="Mobile Number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    cashierData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      //  Form
      getValidationState,
    }
  },
}
</script>
