import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useCashiersForm() {
  const cashierData = ref({})

  const fetchCashier = async params => {
    try {
      const response = await store.dispatch('app-retailer/fetchCashier', params)
      cashierData.value = response.data.data[0]
      return response
    } catch (error) {
      cashierData.value = undefined
      return error
    }
  }

  const updateCashier = async () => {
    try {
      const response = await store.dispatch('app-retailer/updateCashier', cashierData.value)
      if (!response.data.status) {
        return
      }
      router.push({ name: 'retailers-management-retailer-branches-view', params: { id: cashierData.value.branchId } })
      return response
    } catch (error) {
      return error
    }
  }

  return {
    cashierData,

    fetchCashier,
    updateCashier,
  }
}
