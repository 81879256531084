<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(updateCashier)">

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <cashiers-form :cashier-data="cashierData" />

      </b-form>

    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import useCashiersForm from '../cashiers-form/useCashiersForm'
import retailerStoreModule from '../../retailerStoreModule'
import CashiersForm from '../cashiers-form/CashiersForm.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    CashiersForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const RETAILER_APP_STORE_MODULE_NAME = 'app-retailer'

    // Register module
    if (!store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_APP_STORE_MODULE_NAME, retailerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.unregisterModule(RETAILER_APP_STORE_MODULE_NAME)
    })

    const {
      cashierData,

      fetchCashier,
      updateCashier,
    } = useCashiersForm()

    fetchCashier(router.currentRoute.params.id)

    return {
      //  Data
      cashierData,

      //  Action
      updateCashier,
    }
  },
}
</script>
